.customer-option {
	.form-check-input:not(:checked) + .form-check-label {
		.selected-check {
			display: none;
		}
	}
	.form-check-input:checked + .form-check-label {
		background: rgba(25, 255, 25, 0.08);
		.selected-check {
			display: block;
		}
	}
}
